import React from "react";
import StoryPage from "../../components/story-page";
/** @jsx jsx */
import { jsx } from "theme-ui";

const ThisPage = ({ location }) => {
  const imgUrl = "/images/people/bancroft-wright.jpeg";
  const fullName = "Const. Bancroft Anthony Wright";
  const zoomUrl = "";
  const zoomPhoneNumber = "";
  const zoomMeetingId = "";
  const zoomMeetingPasscode = "";
  const birthDate = "March 31, 1966";
  const deathDate = "December 11, 2020";
  const memorialDate = "Saturday December 19th, 2020";
  const memorialTime = "1pm EST";
  const inviteText = "";
  const obituary = ``;
  const storiesRecordUrl = "https://stories.afterword.com/fnwhdgsrj";
  const storiesSubmissionsUrl =
    "https://stories.afterword.com/r9v761w2rydt15bg6lavrvtjgwq7k74fws7r3zyu";
  const recordingEmbedUrl =
    "https://storage.googleapis.com/afterword-static/recordings/const-bancroft-wright-480.mp4";

  return (
    <StoryPage
      location={location}
      imgUrl={imgUrl}
      fullName={fullName}
      zoomUrl={zoomUrl}
      zoomPhoneNumber={zoomPhoneNumber}
      zoomMeetingId={zoomMeetingId}
      zoomMeetingPasscode={zoomMeetingPasscode}
      optInviteChild=""
      customColumnSpacing="2fr 3fr"
      birthDate={birthDate}
      deathDate={deathDate}
      memorialDate={memorialDate}
      memorialTime={memorialTime}
      inviteText={inviteText}
      obituary={obituary}
      storiesRecordUrl={storiesRecordUrl}
      storiesSubmissionsUrl={storiesSubmissionsUrl}
      recordingEmbedUrl={recordingEmbedUrl}
    ></StoryPage>
  );
};

export default ThisPage;
